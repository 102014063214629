import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import AuthContext from './AuthContext';

import Hydraviz from './Hydraviz';

import { ActionButton, FontIcon } from '@fluentui/react';
import ProjectWrapper from './ProjectWrapper';
import ProjectSearch from './ProjectSearch';
import SignUp from './SignUp';
import SignIn from './SignIn';
import Dashboard from './Dashboard';
import Home from './Home';
import axios from 'axios';
import LoadingBlocker from './LoadingBlocker';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import DailyUpdate from './DailyUpdate';

function App() {

  const [user, setUser] = React.useState(null);
  const signout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  const [loading, setLoading] = React.useState(true);

  const updateUser = (u) => setUser(prev => ({...prev, ...u}));

  React.useEffect(() => {
    checkUserToken();
  }, []);

  const checkUserToken = () => {
    setLoading(true);
    let token = localStorage.getItem('token');
    if(token){
      axios.get('/api/auth/check-token', {headers: {token}})
      .then(res => {
        if(res.data.success){
          setUser(res.data.user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(err => {
        // console.log(err);
        setLoading(false);
      })
    } else {
      setLoading(false);
    }
  }

  if(loading) return <LoadingBlocker />
  
  return (
    <AuthContext.Provider value={{user, signout, updateUser}}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path={'/project/:project_key'} render={(props) => {
            
            return <ProjectWrapper {...props}>
              <Hydraviz {...props} apiKey={props.match.params.project_key} />
            </ProjectWrapper>
          }} />
          <Route path='/project' component={ProjectSearch} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/signup' component={SignUp} />
          <Route path='/signin' component={SignIn} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <Route path='/reset-password/:reset_hash' component={ResetPassword} />
          <Route path="/missouri-river-daily-update" component={DailyUpdate} />
          <Route path="/fort-peck-feedback" component={FortPeckRedirect} />
          <Route path='/:project_key' render={(p) => <Redirect to={`/project/${p.match.params.project_key}`} />}/>
          <Route path="/" render={(props) => {
            return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
              <div className='tac'>
                <FontIcon iconName='Search' style={{fontSize: 24}} />
                <p className='title' style={{fontSize: 16}}>Not found</p>
                <ActionButton iconProps={{iconName: 'ChevronLeftMed'}} onClick={() => props.history.push('/')} />
              </div>
            </div>
          }}>
          </Route>
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;

const FortPeckRedirect = (props) => {
  return <Redirect to={`/project/mrric-map-survey/map`} />;
}