import React from 'react';

import { LocationPicker, AltPicker, ChartPicker, ThresholdPicker, LOOKUP_KEYS, labelStyle } from './Hydraviz';
import createPlotlyComponent from 'react-plotly.js/factory';
import DateRange from './DateRange';
import Plotly from 'plotly.js';
import { Callout, DefaultButton, DirectionalHint, getTheme, IconButton, Label, Link, Slider } from '@fluentui/react';
import { DEFAULT_COLORS } from './lib';
import { processShapes, resolveWindow } from './SingleAlternative';

import _ from 'lodash';
import { trimSeries } from './lib/traces';
import LookupKeyPicker from './LookupKeyPicker';

const Plot = createPlotlyComponent(Plotly);

export default function Spaghetti(props){
  const [thresholds, setThresholds] = React.useState([]);
  const [dateRangePickerHidden, toggleDateRangePickerHidden] = React.useState(true);

  const { ALL_DAYS, startCalDay, alternatives=[], YEARS=[], addThreshold, editThreshold, lookupKeys=[] } = props;

  const COLOR_LOOKUP = React.useMemo(() => {
    let holder = {};
    let counter = 0;
    for(let i = YEARS[0]; i <= YEARS[YEARS.length - 1]; i++){
      holder[i] = DEFAULT_COLORS[counter];
      counter++;
      if(counter === (DEFAULT_COLORS.length)){
        counter = 0;
      }
    }
    return holder;
  }, [YEARS]);

  React.useEffect(() => {
    setThresholds(props.thresholds);
  }, [props.thresholds]);

  const dataSet = React.useMemo(() => {
    return props.master.find(item => item.key === props.selectedAlt);
  }, [props.master, props.selectedAlt]);

  const measure = React.useMemo(() => {
    try{
      return dataSet.data.find(set => set.location === props.location).measure;
    } catch(err){
      return ''
    }
  }, [dataSet, props.location]);

  const FILTERED_CAL = React.useMemo(() => ALL_DAYS.filter((d, index) => {
    let startIndex = ALL_DAYS.findIndex(d => d.julian === props.startDay);
    let endIndex = ALL_DAYS.findIndex(d => d.julian === props.endDay);
    return index >= Math.min(startIndex, endIndex) && index <= Math.max(startIndex, endIndex);
  }), [props.endDay, props.startDay, ALL_DAYS]);


  const titleDate = React.useMemo(() => {
    return `${FILTERED_CAL[0].date} - ${FILTERED_CAL[FILTERED_CAL.length - 1].date}`;
  }, [FILTERED_CAL]);

  const chartData = React.useMemo(() => {

    let data = dataSet.data.find(loc => loc.location === props.location);

    if(!data) return [];

    let traces = [];

    YEARS.forEach(year => {
      let visible = props.pickYears.includes(year);
      if(visible || !data.annualData[year]) return;
      
      traces.push({
        key: year,
        id: year,
        meta: {
          year: year
        },
        type: 'scatter',
        showlegend: false,
        mode: 'lines',
        hoverinfo: 'text',
        name: `${data.alternative} - ${year}`,
        text: year,
        ignoreThresholdCalcs: !visible,
        opacity: visible ? 1 : 0.15,
        chartType: 'scenario',
        x: FILTERED_CAL.map(({date}) => date),
        y: trimSeries(FILTERED_CAL, data.annualData[year]),
        line: {
          color: !visible ? getTheme().palette.neutralTertiary : COLOR_LOOKUP[year],
          width: 2
        }
      })
    });

    YEARS.forEach(year => {
      let visible = props.pickYears.includes(year);
      if(!visible || !data.annualData[year]) return;
      traces.push({
        key: year,
        id: year,
        meta: {
          year: year
        },
        type: 'scatter',
        mode: 'lines',
        hoverinfo: 'text',
        name: `${data.alternative} - ${year}`,
        // text: `${data.alternative} - ${year}`,
        ignoreThresholdCalcs: !visible,
        opacity: visible ? 1 : 0.5,
        chartType: 'scenario',
        x: FILTERED_CAL.map(({date}) => date),
        y: trimSeries(FILTERED_CAL, data.annualData[year]),
        text: trimSeries(FILTERED_CAL, data.annualData[year], (trace) => {
          return trace.map((d, i) => `${data.alternative} - ${FILTERED_CAL[i].date}, ${d.year}<br>${d.value} ${data.measure}`)
        }),
        line: {
          color: !visible ? getTheme().palette.neutralTertiary : COLOR_LOOKUP[year],
          width: 2
        }
      })
    });

    lookupKeys.forEach(key => {
      const lookup = LOOKUP_KEYS.find(el => el.key === key);
      let y = FILTERED_CAL.map(({julian}) => data.stats[julian][key]);
      let x = FILTERED_CAL.map(({date}) => date);
      traces.push({
        key: key,
        id: key,
        type: 'scatter',
        mode: 'lines',
        hoverinfo: 'text',
        name: `${data.alternative} - ${lookup.text}`,
        opacity: 1,
        chartType: 'stat',
        x,
        y,
        text: FILTERED_CAL.map(({date}, index) => (`${data.alternative} - ${date}<br>${y[index]} ${measure}`)),
        line: {
          color:  lookup.color,
          width: 3,
          dash: 'dot'
        }
      })
    });

    let startIndex = FILTERED_CAL[0].dayLookup;
    let endIndex = FILTERED_CAL[FILTERED_CAL.length - 1].dayLookup ;

    thresholds.forEach(threshold => {

      let { isWindow, isRange, isStepped, steps, name, visible, color, fill, pattern, stepsLower, upperRange, lowerRange, value } = threshold.data;

      let y = [];
      let text = [];
      let x = [];

      if(isWindow){
        x = resolveWindow(FILTERED_CAL, threshold.data).map(d => d.date);
      } else {
        x = FILTERED_CAL.map((d) => d.date);
      }

      let firstHalfSteps = [].concat(...steps.filter(el => el.julian >= FILTERED_CAL[0].julian));
      let secondHalfSteps = [].concat(...steps.filter(el => el.julian < FILTERED_CAL[0].julian));
      let firstHalfStepsLower = [].concat(...stepsLower.filter(el => el.julian >= FILTERED_CAL[0].julian));
      let secondHalfStepsLower = [].concat(...stepsLower.filter(el => el.julian < FILTERED_CAL[0].julian));
      steps = [...firstHalfSteps, ...secondHalfSteps];
      stepsLower = [...firstHalfStepsLower, ...secondHalfStepsLower];
      
      if(isRange){
        if(isStepped){
          // upper first
          y = steps.slice(startIndex - 1, endIndex).map(({value}) => parseFloat(value));

          // console.log(upper);
          // text = x.map((date) => `${threshold.name}, ${y[]}`)

          traces.push({
            key: `${threshold.id}-up`,
            name: `${name} Upper`,
            visible: visible,
            hoverinfo: 'name',
            x,
            y,
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          })

          //  now lower

          y = stepsLower.slice(startIndex - 1, endIndex).map(({value}) => parseFloat(value));

          // console.log(lower);

          traces.push({
            key: `${threshold.id}-low`,
            name: `${name} - Lower`,
            visible: visible,
            hoverinfo: 'name',
            x,
            y,
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          })

          return;

        } else {
          //  upper first
          y = x.map((d) => upperRange);
          text = x.map((d) => `${name}, ${x[0]} - ${x[x.length - 1]}<br>${lowerRange} - ${upperRange} ${measure}`);
  
          traces.push({
            key: threshold.id,
            name: `${name} <br> ${lowerRange} - ${upperRange}`,
            visible: visible,
            x,
            y,
            text,
            hoverinfo: 'text',
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          });
  
          // then lower
          y = x.map((d) => lowerRange);
          text = x.map((d) => `${name}, ${x[0]} - ${x[x.length - 1]}<br>${lowerRange} - ${upperRange} ${measure}`);
          traces.push({
            key: threshold.id,
            name: `${name}`,
            visible: visible,
            x,
            y,
            fill: fill ? 'tonexty' : false,
            text,
            hoverinfo: 'text',
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          });
          return;
        }
      } else {
        if(isStepped){

          y = steps.filter(({dayLookup}) => dayLookup >= startIndex && dayLookup <= endIndex).map(({value}) => parseFloat(value));
          text = steps.map(({date, value}) => `${name}, ${date}<br>${value} ${measure}`);

        } else {

          y = x.map((d) => value);
          text = x.map((d) => `${name}, ${d}<br>${value} ${measure}`);

        }
      }
      
      traces.push({
        key: threshold.id,
        name: name,
        visible: visible,
        x,
        y,
        text,
        hoverinfo: 'text',
        mode: 'lines',
        line: {
          color: color,
          width: 2,
          dash: pattern
        }
      })
    });

    return traces;
  }, [dataSet, props.pickYears, props.location, props.startDay, props.endDay, thresholds, measure, FILTERED_CAL, COLOR_LOOKUP, YEARS, lookupKeys]);

  const [layout, setLayout] = React.useState(() => {
    return {
      autosize: true,
      hovermode: 'closest',
      showlegend: false,
      title: `${props.location} - ${props.selectedAlt}<br>${titleDate}`,
      legend: {orientation: 'v'},
      yaxis: {
        automargin: true, 
        title: measure,
        autorange: true
      },
      xaxis: {
        automargin: true, 
        ticktext: FILTERED_CAL.map(d => d.date),
        tickmode: 'array',
      },
      font: {
        family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
        size: 14
      }
    }
  });

  const shapeThresholds = React.useMemo(() => {
    return thresholds.filter(el => el.isWindow && el.isRange);
  }, [thresholds]);

  React.useEffect(() => {
    const shapes = processShapes(shapeThresholds, FILTERED_CAL);
    // let shapes = [];
    setLayout(prev => ({...prev, title: `${props.location} - ${props.selectedAlt}<br>${titleDate}`, yaxis: {...prev.yaxis, title: measure}, shapes}));
  }, [props.location, props.selectedAlt, props.startDay, props.endDay, props.pickYear, measure, titleDate, shapeThresholds, FILTERED_CAL]);

  const scenarios = React.useMemo(() => {
    return chartData.filter(t => t.chartType === 'scenario');
  }, [chartData]);

  const minMax = React.useMemo(() => {
    let data = props.master.find(el => el.alternative === props.selectedAlt)?.data.find(el => el.location === props.location);
    if(data) return {minYear: data.minYear, maxYear: data.maxYear};
    else return {minYear: Math.min(...YEARS), maxYear: Math.max(...YEARS)};
  }, [props.master, props.location, props.selectedAlt, YEARS]);

  const captureImage = (gd, e) => {
    Plotly.relayout(gd, {showlegend: true})
    Plotly.downloadImage(gd);
    Plotly.relayout(gd, {showlegend: false});
  };

  const config = {
    modeBarButtonsToRemove: ['toImage'],
    displaylogo: false,
    modeBarButtonsToAdd: [{
      name: 'Capture',
      click: captureImage, 
      icon: {
        width: 1000,
        height: 1000,
        path: 'm500 450c-83 0-150-67-150-150 0-83 67-150 150-150 83 0 150 67 150 150 0 83-67 150-150 150z m400 150h-120c-16 0-34 13-39 29l-31 93c-6 15-23 28-40 28h-340c-16 0-34-13-39-28l-31-94c-6-15-23-28-40-28h-120c-55 0-100-45-100-100v-450c0-55 45-100 100-100h800c55 0 100 45 100 100v450c0 55-45 100-100 100z m-400-550c-138 0-250 112-250 250 0 138 112 250 250 250 138 0 250-112 250-250 0-138-112-250-250-250z m365 380c-19 0-35 16-35 35 0 19 16 35 35 35 19 0 35-16 35-35 0-19-16-35-35-35z',
        transform: 'matrix(1 0 0 -1 0 850)'
      }}]
  }

  const onDoubleClick = () => props.changeFilters({startDay: FILTERED_CAL[0].julian, endDay: FILTERED_CAL[FILTERED_CAL.length - 1].julian});

  const style = {width: '100%', height: '100%'};

  return <div style={{display: 'flex', flex: 1, background: '#fff', padding: 8, height: 'calc(100% - 33px)', maxHeight: 'calc(100% - 33px)'}}>
    <div className='mb3 fr aic sb'>
      <div className='fr aic'>
        <LocationPicker locationGroups={props.locationGroups} toggleShowMapper={props.toggleShowMapper} locations={props.locations} location={props.location} onChange={(location) => props.changeFilters({location})} />
        <AltPicker alternatives={alternatives} alternative={props.selectedAlt} onChange={(selectedAlt) => props.changeFilters({selectedAlt})} />
        <LookupKeyPicker 
          lookupKeys={props.lookupKeys}
          onChange={(key) => {
            if(key === 'clear') return props.changeFilters({lookupKeys: []});
            if(props.lookupKeys.includes(key)){
              props.changeFilters({lookupKeys: props.lookupKeys.filter(el => el !== key)});
            } else {
              props.changeFilters({lookupKeys: props.lookupKeys.concat(key)});
            }
          }} />
        <DateRange startCalDay={props.startCalDay} hidden={dateRangePickerHidden} toggleDateRangePickerHidden={toggleDateRangePickerHidden} startDay={props.startDay} endDay={props.endDay} onChange={(startDay, endDay) => props.changeFilters({startDay, endDay})} />
      </div>
      <ChartPicker chartType={props.chartType} onChange={(chartType) => props.changeFilters({chartType})} />
    </div>

    <MultiYearPicker 
      YEARS={YEARS}
      {...minMax} 
      startCalDay={startCalDay}
      currentlyPicked={props.pickYears}
      changeFilters={props.changeFilters}
      onClear={() => props.changeFilters({pickYears: []})}
      onChange={(year) => {
        props.changeFilters({pickYears: props.pickYears.includes(year) ? props.pickYears.filter(y => y !== year) : props.pickYears.concat(year).sort((a, b) => a-b)});
      }} />

    <ThresholdPicker 
      ALL_DAYS={ALL_DAYS} 
      FILTERED_CAL={FILTERED_CAL}
      startCalDay={startCalDay}
      setThresholdInfo={props.setThresholdInfo} 
      location={props.location}
      editThreshold={editThreshold}
      addThreshold={addThreshold} 
      startDay={props.startDay} 
      endDay={props.endDay} 
      thresholds={thresholds} 
      changeFilters={props.changeFilters} 
      scenarios={scenarios}/>
    {
      chartData.length > 0 ? (
        <div className='f1'>
          <Plot
            style={style}
            onDoubleClick={onDoubleClick}
            className='ms-depth-4'
            layout={layout}
            config={config}
            useResizeHandler
            data={chartData} />
        </div>
      ) : <div className='fr jcc aic empty ms-depth-4 f1'>
        <p>Unable to find data at this location</p>
      </div>
    }
  </div>
}


export function MultiYearPicker(props){

  const { minYear, maxYear } = props;

  const [sliderYear, setSliderYear] = React.useState(props.pickYear);

  const [isOpen, toggleIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  const restrictedYears = React.useMemo(() => {
    return _.range(minYear, maxYear + 1, 1);
  }, [minYear, maxYear]);

  const COLOR_LOOKUP = React.useMemo(() => {
    let holder = {};
    let counter = 0;
    for(let i = minYear; i <= maxYear; i++){
      holder[i] = DEFAULT_COLORS[counter];
      counter++;
      if(counter === (DEFAULT_COLORS.length)){
        counter = 0;
      }
    }
    return holder;
  }, [minYear, maxYear]);

  React.useEffect(() => {
    const to = setTimeout(() => {
      props.changeFilters({pickYear: sliderYear});
    }, 250);
    return () => clearTimeout(to);
  }, [sliderYear]);

  const toggleYear = year => {
    props.onChange(year)
  }

  if(props.singleYearEnabled && props.pickYearVisible){
    return <div style={{marginBottom: 16}}>
      <Label styles={labelStyle}>Year</Label>
      <div ref={ref} style={{display: 'flex'}}>
        <div style={{display: 'flex', alignItems: 'center', width: 'max-content', padding: '2px 8px', borderColor: `#c8c6c4`, borderBottom: `3px solid`, borderBottomColor: `${getTheme().palette.magentaLight}`}} className='hover-label f1'>
          <IconButton onClick={() => {
            setSliderYear(props.pickYear - 1);
            // props.changeFilters({pickYear: props.pickYear - 1})
          }} disabled={parseFloat(props.pickYear) === parseFloat(minYear)} styles={{root: {height: '100%', background: "none !important", padding: 0, width: 'auto'}}} iconProps={{iconName: "ChevronLeftMed"}} />
          <span className='f1 tac' onClick={() => toggleIsOpen(true)}>{sliderYear}</span>
          <IconButton onClick={() => {
            setSliderYear(props.pickYear + 1);
            // props.changeFilters({pickYear: props.pickYear + 1})
          }} disabled={parseFloat(props.pickYear) === parseFloat(maxYear)} styles={{root: {height: '100%', background: "none !important", padding: 0, width: 'auto'}}} iconProps={{iconName: "ChevronRightMed"}} />
        </div>

          
          <DefaultButton text="Multi Year" onClick={() => props.changeFilters({pickYearVisible: !props.pickYearVisible})} styles={{root: { borderRadius: 4, marginLeft: 8, textAlign: 'center'}, flexContainer: {width: '100%'}}} className='hover-label f1' />
        </div>
        <Callout directionalHint={DirectionalHint.bottomCenter} calloutWidth={200} hidden={!isOpen} target={ref} isBeakVisible={false}  onDismiss={() => toggleIsOpen(false)}>
          <Slider 
            showValue={false} 
            min={minYear} 
            max={maxYear} 
            value={sliderYear} 
            onChange={(val) => setSliderYear(val)} />
        </Callout>
    </div>
  }

  return <div style={{marginBottom: 16, maxHeight: '320px', height: 200, display: 'flex', flexDirection: 'column'}}>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <Label styles={labelStyle}>Years</Label>
      <div>
        <Link onClick={() => props.changeFilters({pickYears: restrictedYears})} style={{marginRight: 8}}>Select All</Link>
        <Link onClick={() => props.changeFilters({pickYears: []})}>Clear</Link>
      </div>
    </div>
    <div style={{display: 'flex', flexWrap: 'wrap', overflowY: 'auto', flex: 1, border: '1px solid', padding: '8px 0px 8px 8px', borderRadius: 4, borderRight: 'none', borderColor: `${getTheme().palette.neutralLight}`, boxShadow: '0px 2px inner #000'}}>
      <div className='hover-label' style={{marginRight: 4, marginBottom: 4, borderBottomWidth: 2}} onClick={() => props.changeFilters({pickYearVisible: true})}>Single Year</div>
      {restrictedYears?.map(y => {
        let style = {padding: `2px 4px`, marginRight: 4, marginBottom: 4, borderRadius: 4, cursor: 'pointer', userSelect: 'none', borderBottom: '2px solid #c8c6c4'};
        if(props.currentlyPicked.includes(y)){
          style.borderBottom = `2px solid ${COLOR_LOOKUP[y]}`;
        }
        return <div className='hover-label' key={y} style={style} onClick={() => toggleYear(y)} onDoubleClick={() => props.changeFilters({pickYears: [y]})}>{y}</div>
      })}
    </div>
  </div>
}